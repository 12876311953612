import { Inject, Injectable } from "@angular/core";
import { CLIPBOARD } from "../tokens";
import { ToastService } from "src/app/services/toast.service";

@Injectable({
  providedIn: "root",
})
export class CopyValueToClipboardService {
  constructor(
    @Inject(CLIPBOARD)
    private readonly clipboard: Clipboard,
    private readonly toastService: ToastService,
  ) {}

  public copy(value: string, feedbackMessage?: string): void {
    this.clipboard.writeText(value).then(() => {
      if (feedbackMessage) {
        this.toastService.show({ text: feedbackMessage });
      }
    });
  }
}
